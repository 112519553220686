import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { tableDataParser } from './dataParser';
import { NavigationPaths } from '../../common/Environment';

import Table from '../../components/Table';
import Header from '../../components/Header';
import AdminService from '../../services/adminService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './TopicsPage.scss';

const TopicsPage = () => {
  const [loader, setLoader] = useState(false);
  const [tableContent, setTableContent] = useState([]);
  const tableHeaders = ['S. No.', 'Database ID', 'Topic', 'Active'];

  const adminService = new AdminService();

  useEffect(() => {
    getTopics();
  }, []); // eslint-disable-line

  const getTopics = () => {
    setLoader(true);

    adminService
      .getTopics()
      .then((responseData) => {
        if (responseData) {
          const parsedContentArr = tableDataParser(responseData.data);

          setLoader(false);

          setTableContent(parsedContentArr);
        }
      })
      .catch((errorData) => {
        setLoader(false);
        console.log(errorData);
      });
  };

  const handleTopicToggle = (topicDetails) => {
    if (Array.isArray(topicDetails)) {
      setLoader(true);
  
      adminService
        .updateTopic({ topicId: topicDetails[1], isActive: topicDetails[3] === 'Yes' ? false : true })
        .then(() => getTopics())
        .catch((errorData) => {
          setLoader(false);
          console.log(errorData);
        });
    }
  };

  return (
    <div className='page-container' style={styles}>
      <Helmet>
        <title>Worksheet Warehouse - Topics</title>
      </Helmet>

      <Header />

      <SideNavigation />

      <div className='data-container'>
        <div className='data-container-wrapper'>
          <div className='data-container-header d-flex align-items-center justify-content-between'>
            <h1 className='data-container-title'>
              Topics
            </h1>
            <Link
                to={NavigationPaths.CREATETOPICSPAGE}
                className="button button-primary"
              >
                Add Topic
              </Link>
          </div>

          <Table
            hasActions={true}
            hasDisable={true}
            loaderState={loader}
            headerData={tableHeaders}
            tableData={tableContent}
            disablePath={handleTopicToggle}
            editPath={NavigationPaths.TOPICSIDPAGE}
            />
        </div>
      </div>
    </div>
  );
};

export default withAuthentication(TopicsPage);
