export const tableDataParser = (responseData, columnCount) => {
  if (responseData && responseData.length !== 0) {
    let tableDataArr = [];

    for (let rowIndex = 0; rowIndex < responseData.length; rowIndex++) {
      let currentRowArr = [];

      currentRowArr.push(`${rowIndex + 1}.`);
      currentRowArr.push(responseData[rowIndex]._id);
      currentRowArr.push(responseData[rowIndex].worksheetTitle);
      currentRowArr.push(responseData[rowIndex].subtopics?.join(", "));
      currentRowArr.push(responseData[rowIndex].topicId?.topicName);
      currentRowArr.push(responseData[rowIndex].subjectId?.subjectName);
      currentRowArr.push(responseData[rowIndex].classes?.join(", "));
      currentRowArr.push(responseData[rowIndex]?.isActive ? "Yes" : "No");

      tableDataArr.push(currentRowArr);
    }

    return tableDataArr;
  } else {
    return [];
  }
};
