import {axiosInstance, cdnAxiosInstance} from './CreateService';
import { ApiConstants } from '../common/Environment';

export default class AdminService {
  uploadMedia(dataPayload) {
    const payload = new FormData();
    payload.append('file', dataPayload.file);
    payload.set('directoryName', dataPayload.directoryName);

    return cdnAxiosInstance.post(ApiConstants.UPLOAD, payload);
  }

  updateClass(dataPayload) {
    return axiosInstance.put(
      ApiConstants.CLASSES_ID(dataPayload.classId), dataPayload
    );
  }

  getClasses() {
    return axiosInstance.get(ApiConstants.CLASSES);
  }

  getClassDetails(dataPayload) {
    return axiosInstance.get(ApiConstants.CLASSES_ID(dataPayload.classId));
  }
  
  createClass(dataPayload) {
    return axiosInstance.post(ApiConstants.CLASSES, dataPayload);
  }

  getSubjects() {
    return axiosInstance.get(ApiConstants.SUBJECTS);
  }

  getSubjectDetails(dataPayload) {
    return axiosInstance.get(ApiConstants.SUBJECTS_ID(dataPayload.subjectId));
  }

  createSubject(dataPayload) {
    return axiosInstance.post(ApiConstants.SUBJECTS, dataPayload);
  }

  updateSubject(dataPayload) {
    return axiosInstance.put(
      ApiConstants.SUBJECTS_ID(dataPayload.subjectId), dataPayload
    );
  }

  getSubjectsAndClasses() {
    return axiosInstance.get(ApiConstants.SUBJECTS_CLASSES);
  }

  getTopics() {
    return axiosInstance.get(ApiConstants.TOPICS);
  }

  getTopicDetails(dataPayload) {
    return axiosInstance.get(ApiConstants.TOPICS_ID(dataPayload.topicId));
  }

  createTopic(dataPayload) {
    return axiosInstance.post(ApiConstants.TOPICS, dataPayload);
  }

  updateTopic(dataPayload) {
    return axiosInstance.put(
      ApiConstants.TOPICS_ID(dataPayload.topicId), dataPayload
    );
  }

  findTopics(dataPayload) {
    return axiosInstance.post(ApiConstants.FIND_TOPICS, dataPayload);
  }

  getSubtopics() {
    return axiosInstance.get(ApiConstants.SUBTOPICS);
  }

  getSubtopicDetails(dataPayload) {
    return axiosInstance.get(ApiConstants.SUBTOPICS_ID(dataPayload.subtopicId));
  }

  createSubtopic(dataPayload) {
    return axiosInstance.post(ApiConstants.SUBTOPICS, dataPayload);
  }

  updateSubtopic(dataPayload) {
    return axiosInstance.put(
      ApiConstants.SUBTOPICS_ID(dataPayload.subtopicId), dataPayload
    );
  }

  findSubtopics(dataPayload) {
    return axiosInstance.post(ApiConstants.FIND_SUBTOPICS, dataPayload);
  }

  getWorksheets() {
    return axiosInstance.get(ApiConstants.WORKSHEETS);
  }

  getWorksheetDetails(dataPayload) {
    return axiosInstance.get(ApiConstants.WORKSHEETS_ID(dataPayload.worksheetId));
  }

  createWorksheet(dataPayload) {
    return axiosInstance.post(ApiConstants.WORKSHEETS, dataPayload);
  }

  updateWorksheet(dataPayload) {
    return axiosInstance.put(
      ApiConstants.WORKSHEETS_ID(dataPayload.worksheetId), dataPayload
    );
  }

  getPrintAnalytics() {
    return axiosInstance.get(ApiConstants.WORKSHEETS_PRINT_ANALYTICS);
  }

  getResources() {
    return axiosInstance.get(ApiConstants.WORKSHEETS_RESOURCES);
  }
}
