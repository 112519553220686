import React, { useEffect } from 'react';

import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NavigationPaths } from '../../common/Environment';

const withAuthentication = (WrappedComponent) => {
  const WithAuthenticationHoc = (props) => {
    const adminDetails = useSelector((state) => state?.admin?.adminDetails);

    useEffect(() => {
      // Reset Page Scroll to Zero
      window.scrollTo(0, 0);
    }, []); // eslint-disable-line

    useEffect(() => {
      if (props.lockBodyScroll) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
      }
    }, [props.lockBodyScroll]); // eslint-disable-line

    if (adminDetails && adminDetails.accessToken) {
      return <WrappedComponent {...props} />;
    } else {
      return <Navigate to={NavigationPaths.LOGIN} />;
    }
  };

  return WithAuthenticationHoc;
};

export default withAuthentication;
