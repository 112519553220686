import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

import Header from '../../components/Header';
import PageLoader from '../../components/PageLoader';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './HomePage.scss';

const HomePage = () => {
  const [loader, setLoader] = useState(false);
  return (
    <div className='page-container' style={styles}>
      <Helmet>
        <title>Worksheet Warehouse - Application Dashboard</title>
      </Helmet> 

      <Header />

      <SideNavigation />

      <div className='home-container'>
      </div>

      {loader && <PageLoader />}
    </div>
  );
};

export default withAuthentication(HomePage);
