import get from 'lodash/get';
import React, { useState } from 'react';

import {
  loginPayload,
  loginValidation,
  verifyOTPPayload,
  verifyOTPValidation,
} from './dataManager';
import { useDispatch } from 'react-redux';
import { ReduxConstants } from '../../common/Environment';

import PageLoader from '../../components/PageLoader';
import AuthService from '../../services/authService';
import withLogin from '../../hocs/LoginGuard/withLogin';
import {axiosInstance, cdnAxiosInstance} from '../../services/CreateService';
import Logo from '../../images/logo.svg';

import styles from './LoginPage.scss';

const LoginPage = (props) => {
  const dispatch = useDispatch();
  const authService = new AuthService();
  const [loader, setLoader] = useState(false);
  const [isOtpRequested, setIsOtpRequested] = useState(false);
  const [formData, setFormData] = useState({
    otp: '',
    mobileNumber: '',
    countryCode: '91',
  });

  const handleOTPSubmit = () => {
    const isFormValid = verifyOTPValidation(formData);
    if (!isFormValid) {
      return;
    }

    const dataPayload = verifyOTPPayload(formData);

    setLoader(true);
    authService
      .verifyOTP(dataPayload)
      .then((responseData) => {
        setLoader(false);
        if (responseData.data) {
          axiosInstance.defaults.headers.common[
            'Authorization'
          ] = `${responseData.data.accessToken}`;
          cdnAxiosInstance.defaults.headers.common[
            'Authorization'
          ] = `${responseData.data.accessToken}`;

          dispatch({
            type: ReduxConstants.LOGIN,
            payload: responseData.data,
          });
        }
      })
      .catch((errorData) => {
        setLoader(false);
        console.log(get(errorData, 'message'));
      });
  };

  const requestOTPHandler = () => {
    const isFormValid = loginValidation(formData);
    if (!isFormValid) {
      return;
    }

    const dataPayload = loginPayload(formData);

    setLoader(true);
    authService
      .login(dataPayload)
      .then((responseData) => {
        setLoader(false);
        setIsOtpRequested(true);
      })
      .catch((errorData) => {
        setLoader(false);
      });
  };

  const setStateValues = (fieldName, fieldValue) => {
    const formDataCopy = Object.assign({}, formData);
    formDataCopy[fieldName] = fieldValue;
    setFormData(formDataCopy);
  };

  return (
    <div className='page-container page-container--nopad' style={styles}>
      <div className='login-container'>
        <div className='login-container-wrapper'>
          <img src={Logo} className='login-container-brand' alt='WY' />

          <div className='login-container-header'>
            <h4>Administrator Login</h4>
          </div>

          <div className='login-container-content'>
            <div className='input-wrapper'>
              <input
                type='text'
                className='input-field'
                maxLength={10}
                placeholder='Mobile Number'
                value={formData.mobileNumber}
                onChange={(event) =>
                  setStateValues('mobileNumber', event.currentTarget.value)
                }
              />
            </div>
            <div className='input-wrapper'>
              <button
                type='button'
                className='button button-primary'
                onClick={requestOTPHandler}
              >
                {!isOtpRequested ? 'Request OTP' : 'Resend OTP'}
              </button>
            </div>

            {isOtpRequested && (
              <>
                <div className='input-wrapper'>
                  <input
                    type='text'
                    className='input-field'
                    maxLength={4}
                    placeholder='One Time Password (OTP)'
                    value={formData.otp}
                    onChange={(event) =>
                      setStateValues('otp', event.currentTarget.value)
                    }
                  />
                </div>
                <div className='input-wrapper'>
                  <button
                    type='button'
                    className='button button-primary'
                    onClick={() => handleOTPSubmit()}
                  >
                    Sign In
                  </button>
                </div>
              </>
            )}
            <p className='login-container-disclaimer'>
              NOTICE: Wise Owl Learning Private Limited, Hyderabad, IN reserves the right to
              administration of this website and monitors every visitor to this
              and other areas of this website. Your use of this website is
              subject to be monitored and tracked. By using this website and/or
              making any attempts to sabotage its integrity will be tracked and
              be subjected to international jurisdictions, wherever applicable.
            </p>
          </div>
        </div>
      </div>

      {loader && <PageLoader />}
    </div>
  );
};

export default withLogin(LoginPage);
