import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { NavigationPaths } from '../../common/Environment';

const withLogin = (ChildComponent) => {
  const WithLoginHoc = (props) => {
    const isLoggedIn = useSelector(
      (state) => state?.admin?.adminDetails?.accessToken
    );

    if (isLoggedIn) {
      return <Navigate to={NavigationPaths.HOMEPAGE} />;
    }

    return <ChildComponent />;
  };

  return WithLoginHoc;
};

export default withLogin;
