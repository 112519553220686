import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { NavigationPaths } from "../../common/Environment";

import startCase from "lodash/startCase";
import Header from "../../components/Header";
import Uploader from "../../components/Uploader";
import PageLoader from "../../components/PageLoader";
import AdminService from "../../services/adminService";
import SideNavigation from "../../components/SideNavigation";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";

import styles from "./CopyWorksheetPage.scss";

const CopyWorksheetPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    skill: "",
    fileUrl: "",
    classId: "",
    topicId: "",
    keywords: "",
    subjectId: "",
    subtopicId: "",
    difficulty: "",
    worksheetId: "",
    screenshotUrl: "",
    worksheetTitle: "",
    learningObjective: "",
    includesAnswerKey: "false",
  });
  const [slug, setSlug] = useState("");
  const [topics, setTopics] = useState([]);
  const [classes, setClasses] = useState([]);
  const [loader, setLoader] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [showServerErrors, setShowServerErrors] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const adminService = new AdminService();

  useEffect(() => {
    getWorksheetDetails(params.id);
  }, [params.id]); // eslint-disable-line

  useEffect(() => {
    getSubjectsAndClasses();
  }, []); // eslint-disable-line

  const getWorksheetDetails = (worksheetId) => {
    if (!worksheetId) {
      return;
    }

    const dataPayload = {
      worksheetId,
    };

    adminService
      .getWorksheetDetails(dataPayload)
      .then((responseData) => {
        if (responseData.data) {
          setFormData({
            skill: responseData.data?.skill,
            fileUrl: responseData.data?.fileUrl,
            topicId: responseData.data?.topicId?._id,
            classId: responseData.data?.classId?._id,
            difficulty: responseData.data?.difficulty,
            subjectId: responseData.data?.subjectId?._id,
            subtopicId: responseData.data?.subtopicId?._id,
            screenshotUrl: responseData.data?.screenshotUrl,
            worksheetTitle: responseData.data?.worksheetTitle,
            learningObjective: responseData.data?.learningObjective,
            keywords: responseData.data?.keywords?.join(", ") || "",
            includesAnswerKey: responseData.data?.includesAnswerKey ? "true" : "false",
          });
          setSlug(responseData.data?.slug);
          findTopics(responseData.data?.subjectId?._id, responseData.data?.classId?._id);
          findSubtopics(responseData.data?.topicId?._id, responseData.data?.subjectId?._id, responseData.data?.classId?._id);
        }
      })
      .catch((errorData) => {
        setLoader(false);
        setShowServerErrors(
          errorData.message ||
            "Something went wrong. Please verify all the data entered is correct and then try again."
        );
      });
  };

  const getSubjectsAndClasses = () => {
    setLoader(true);

    adminService
      .getSubjectsAndClasses()
      .then((responseData) => {
        if (responseData) {
          setLoader(false);
          setClasses(responseData.data?.classes);
          setSubjects(responseData.data?.subjects)
        }
      })
      .catch((errorData) => {
        setLoader(false);
        console.log(errorData);
      });
  };

  const handleFormSubmit = () => {
    const valid = validateFormData();

    if (valid) {
      setLoader(true);

      adminService
        .createWorksheet({
          ...formData,
          includesAnswerKey: formData.includesAnswerKey === "true",
          keywords: formData.keywords ? formData.keywords.split(",").map(keyword => startCase(keyword.trim().toLowerCase())) : [],
        })
        .then((responseData) => {
          setLoader(false);
          if (responseData && responseData.data) {
            navigate(NavigationPaths.WORKSHEETSPAGE);
          }
        })
        .catch((errorData) => {
          setLoader(false);
          setShowServerErrors(
            errorData.message ||
              "Something went wrong. Please verify all the data entered is correct and then try again."
          );
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        });
    }
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    setFormData((state) => ({
      ...state,
      [`${fieldName}`]: fieldValue,
    }));

    if (fieldName === "classId") {
      setFormData((state) => ({
        ...state,
        subjectId: "",
        topicId: "",
        subtopicId: "",
      }));
    } else if (fieldName === "subjectId") {
      setFormData((state) => ({
        ...state,
        topicId: "",
        subtopicId: "",
      }));
      findTopics(fieldValue);
    } else if (fieldName === "topicId") {
      setFormData((state) => ({
        ...state,
        subtopicId: "",
      }));
      findSubtopics(fieldValue);
    }
  };

  const findTopics = (subjectId, classId) => {
    setLoader(true);

    adminService
      .findTopics({ subjectId, classId: formData.classId || classId })
      .then((responseData) => {
        if (responseData) {
          setLoader(false);
          setTopics(responseData.data);
        }
      })
      .catch((errorData) => {
        setLoader(false);
        console.log(errorData);
      });
  };

  const findSubtopics = (topicId, subjectId, classId) => {
    setLoader(true);

    adminService
      .findSubtopics({ topicId, subjectId: formData.subjectId || subjectId, classId: formData.classId || classId })
      .then((responseData) => {
        if (responseData) {
          setLoader(false);
          setSubtopics(responseData.data);
        }
      })
      .catch((errorData) => {
        setLoader(false);
        console.log(errorData);
      });
  };

  const validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    if (formData.classId === "") {
      errorMessagesArr.push("Class is a required field.");
      isFormValid = false;
    }

    if (formData.subjectId === "") {
      errorMessagesArr.push("Subject is a required field.");
      isFormValid = false;
    }

    if (formData.topicId === "") {
      errorMessagesArr.push("Topic is a required field.");
      isFormValid = false;
    }

    if (formData.subtopicId === "") {
      errorMessagesArr.push("Sub Topic is a required field.");
      isFormValid = false;
    }

    if (formData.worksheetTitle === "") {
      errorMessagesArr.push("Worksheet Title is a required field.");
      isFormValid = false;
    }

    if (formData.skill === "") {
      errorMessagesArr.push("Skill is a required field.");
      isFormValid = false;
    }

    if (formData.difficulty === "") {
      errorMessagesArr.push("Difficulty is a required field.");
      isFormValid = false;
    }

    if (formData.learningObjective === "") {
      errorMessagesArr.push("Learning Objective is a required field.");
      isFormValid = false;
    }

    if (formData.fileUrl === "") {
      errorMessagesArr.push("File URL is a required field.");
      isFormValid = false;
    }

    if (formData.screenshotUrl === "") {
      errorMessagesArr.push("Screenshot URL is a required field.");
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      setShowServerErrors("");
      setShowValidationErrors(true);
      setValidationErrors(errorMessagesArr);

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      setValidationErrors([]);
      setShowServerErrors("");
      setShowValidationErrors(false);
    }

    return isFormValid;
  };

  return (
    <div className="page-container" style={styles}>
      <Helmet>
        <title>Worksheet Warehouse - Create Worksheet Copy</title>
      </Helmet>

      <Header />

      <SideNavigation />

      <div className="copyworksheet-container">
        <Uploader />
        <div className="copyworksheet-container-wrapper">
          <h1 className="copyworksheet-container-title">Create Worksheet Copy</h1>

          <div className="copyworksheet-container-content">
            {showValidationErrors && (
              <div className="error-wrapper">
                <h6>Error!</h6>

                {validationErrors.map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            )}

            {showServerErrors !== "" && (
              <div className="error-wrapper">
                <h6>Error!</h6>

                <p>{showServerErrors}</p>
              </div>
            )}

            <div className="input-wrapper">
              <label>Class</label>
              <select
                value={formData.classId}
                className="input-select"
                onChange={(event) =>
                  handleFieldUpdate("classId", event.target.value)
                }
              >
                <option value={""} disabled>
                  Choose a class
                </option>
                {classes.map((classDetails, index) => {
                  return (
                    <option key={index} value={classDetails._id}>
                      {classDetails.className}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-wrapper">
              <label>Subjects</label>
              <select
                value={formData.subjectId}
                className="input-select"
                disabled={!formData.classId}
                onChange={(event) =>
                  handleFieldUpdate("subjectId", event.target.value)
                }
              >
                <option value={""} disabled>
                  Choose a subject
                </option>
                {subjects.map((subjectDetails, index) => {
                  return (
                    <option key={index} value={subjectDetails._id}>
                      {subjectDetails.subjectName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-wrapper">
              <label>Topics</label>
              <select
                value={formData.topicId}
                className="input-select"
                disabled={!formData.classId || !formData.subjectId}
                onChange={(event) =>
                  handleFieldUpdate("topicId", event.target.value)
                }
              >
                <option value={""} disabled>
                  Choose a Topic
                </option>
                {topics.map((topicDetails, index) => {
                  return (
                    <option key={index} value={topicDetails._id}>
                      {topicDetails.topicName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-wrapper">
              <label>Sub Topics</label>
              <select
                value={formData.subtopicId}
                className="input-select"
                disabled={!formData.classId || !formData.subjectId || !formData.topicId}
                onChange={(event) =>
                  handleFieldUpdate("subtopicId", event.target.value)
                }
              >
                <option value={""} disabled>
                  Choose a Sub Topic
                </option>
                {subtopics.map((subtopicDetails, index) => {
                  return (
                    <option key={index} value={subtopicDetails._id}>
                      {subtopicDetails.subtopicName}
                    </option>
                  );
                })}
              </select>
            </div>

            <hr />

            <div className="input-wrapper">
              <h5 className="worksheetdetails-container-text">Worksheet Slug: {slug}</h5>
            </div>

            <div className="input-wrapper">
              <label>Worksheet Title</label>
              <input
                type="text"
                className="input-field"
                placeholder="Worksheet Title"
                value={formData.worksheetTitle}
                onChange={(event) =>
                  handleFieldUpdate("worksheetTitle", event.currentTarget.value)
                }
              />
            </div>

            <div className="input-wrapper">
              <label>Skill</label>
              <input
                type="text"
                className="input-field"
                placeholder="Skill"
                value={formData.skill}
                onChange={(event) =>
                  handleFieldUpdate("skill", event.currentTarget.value)
                }
              />
            </div>

            <div className="input-wrapper">
              <label>Difficulty</label>
              <input
                type="text"
                className="input-field"
                placeholder="Difficulty"
                value={formData.difficulty}
                onChange={(event) =>
                  handleFieldUpdate("difficulty", event.currentTarget.value)
                }
              />
            </div>

            <div className="input-wrapper">
              <label>Learning Objective</label>
              <textarea
                rows={3}
                type="text"
                className="input-field"
                placeholder="Learning Objective"
                value={formData.learningObjective}
                onChange={(event) =>
                  handleFieldUpdate(
                    "learningObjective",
                    event.currentTarget.value
                  )
                }
              />
            </div>

            <div className="input-wrapper">
              <label>File URL</label>
              <input
                type="text"
                className="input-field"
                placeholder="File URL"
                value={formData.fileUrl}
                onChange={(event) =>
                  handleFieldUpdate("fileUrl", event.currentTarget.value)
                }
              />
            </div>

            <div className="input-wrapper">
              <label>Screenshot URL</label>
              <input
                type="text"
                className="input-field"
                placeholder="Screenshot URL"
                value={formData.screenshotUrl}
                onChange={(event) =>
                  handleFieldUpdate("screenshotUrl", event.currentTarget.value)
                }
              />
            </div>

            <div className="input-wrapper">
              <label>Keywords</label>
              <input
                type="text"
                className="input-field"
                value={formData.keywords}
                placeholder="Enter comma separated keywords"
                onChange={(event) =>
                  handleFieldUpdate("keywords", event.currentTarget.value)
                }
              />
            </div>

            <div className="input-wrapper">
              <label>Includes Answer Key</label>
              <select
                value={formData.includesAnswerKey}
                className="input-select"
                onChange={(event) =>
                  handleFieldUpdate("includesAnswerKey", event.target.value)
                }
              >
                <option value={"false"}>No</option>
                <option value={"true"}>Yes</option>
              </select>
            </div>

            <div className="input-wrapper">
              <button
                type="button"
                onClick={handleFormSubmit}
                className="button button-primary button-spaced"
              >
                Create Worksheet Copy
              </button>
            </div>
          </div>
        </div>
      </div>

      {loader && <PageLoader />}
    </div>
  );
};

export default withAuthentication(CopyWorksheetPage);
