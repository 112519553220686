import { ReduxConstants } from '../common/Environment';
import {axiosInstance, cdnAxiosInstance} from '../services/CreateService';

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case ReduxConstants.LOGIN:
      return {
        ...state,
        adminDetails: action.payload,
      };

    case ReduxConstants.LOGOUT:
      return {
        ...state,
        adminDetails: {},
      };

    case 'persist/REHYDRATE':
      // Check if Payload Exists
      if (action.payload) {
        // Check if Payload Contains User Key
        if (action.payload.admin) {
          // Check if User Key Has Own Property Value
          if (action.payload.admin.hasOwnProperty('adminDetails')) {
            axiosInstance.defaults.headers.common[
              'Authorization'
            ] = `${action.payload.admin.adminDetails.accessToken}`;
            cdnAxiosInstance.defaults.headers.common[
              'Authorization'
            ] = `${action.payload.admin.adminDetails.accessToken}`;
          }
        }
      }
      return state;

    default: {
      return state;
    }
  }
};

export default authReducer;
