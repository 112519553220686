import { NavigationPaths } from "../../common/Environment";

export const tableDataParser = (responseData, columnCount) => {
  if (responseData && responseData.length !== 0) {
    let tableDataArr = [];

    for (let rowIndex = 0; rowIndex < responseData.length; rowIndex++) {
      let currentRowArr = [];

      currentRowArr.push(`${rowIndex + 1}.`);
      currentRowArr.push(responseData[rowIndex].userId?.udiseCode);
      currentRowArr.push(
        <a
          target="_blank"
          rel="noreferrer"
          href={NavigationPaths.WORKSHEETSIDPAGE.replace(
            ":id",
            responseData[rowIndex].worksheetId?._id
          )}
        >
          {responseData[rowIndex].worksheetId?.worksheetTitle}
        </a>
      );
      currentRowArr.push(responseData[rowIndex].count);

      tableDataArr.push(currentRowArr);
    }

    return tableDataArr;
  } else {
    return [];
  }
};
