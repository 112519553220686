import React, { useEffect } from 'react';
import Loader from '../../images/loader.gif';
import styles from './PageLoader.scss';

const PageLoader = () => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
  }, []); // eslint-disable-line

  useEffect(() => {
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []); // eslint-disable-line

  return (
    <div
      className='global-loader d-flex align-items-center justify-content-center'
      style={styles}
    >
      <div className='global-loader-wrapper text-center'>
        <img src={Loader} className='global-loader-image' alt='Loading' />
        <h4 className='global-loader-text'>Loading...</h4>
      </div>
    </div>
  );
};

export default PageLoader;
