import {axiosInstance} from './CreateService';
import { ApiConstants } from '../common/Environment';

export default class AuthService {
  login(dataPayload) {
    return axiosInstance.post(ApiConstants.LOGIN, dataPayload);
  }

  verifyOTP(dataPayload) {
    return axiosInstance.post(ApiConstants.VERIFY_OTP, dataPayload);
  }
}
