import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { NavigationPaths } from "../../common/Environment";

import Header from "../../components/Header";
import PageLoader from "../../components/PageLoader";
import AdminService from "../../services/adminService";
import SideNavigation from "../../components/SideNavigation";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";

import styles from "./AddTopicPage.scss";

const AddTopicPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    topicName: "",
  });
  const [loader, setLoader] = useState(false);
  const [showServerErrors, setShowServerErrors] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const adminService = new AdminService();

  const handleFormSubmit = () => {
    const valid = validateFormData();

    if (valid) {
      setLoader(true);

      adminService
        .createTopic(formData)
        .then((responseData) => {
          setLoader(false);
          if (responseData && responseData.data) {
            navigate(NavigationPaths.TOPICSPAGE);
          }
        })
        .catch((errorData) => {
          setLoader(false);
          setShowServerErrors(
            errorData.message ||
              "Something went wrong. Please verify all the data entered is correct and then try again."
          );
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        });
    }
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    setFormData((state) => ({
      ...state,
      [`${fieldName}`]: fieldValue,
    }));
  };

  const validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    if (formData.topicName === "") {
      errorMessagesArr.push("Topic Name is a required field.");
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      setShowServerErrors("");
      setShowValidationErrors(true);
      setValidationErrors(errorMessagesArr);

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      setValidationErrors([]);
      setShowServerErrors("");
      setShowValidationErrors(false);
    }

    return isFormValid;
  };

  return (
    <div className="page-container" style={styles}>
      <Helmet>
        <title>Worksheet Warehouse - Add Topic</title>
      </Helmet>

      <Header />

      <SideNavigation />

      <div className="addtopic-container">
        <div className="addtopic-container-wrapper">
          <h1 className="addtopic-container-title">Add Topic</h1>

          <div className="addtopic-container-content">
            {showValidationErrors && (
              <div className="error-wrapper">
                <h6>Error!</h6>

                {validationErrors.map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            )}

            {showServerErrors !== "" && (
              <div className="error-wrapper">
                <h6>Error!</h6>

                <p>{showServerErrors}</p>
              </div>
            )}

            <div className="input-wrapper">
              <label>Topic Name</label>
              <input
                type="text"
                className="input-field"
                placeholder="Topic Name"
                value={formData.topicName}
                onChange={(event) =>
                  handleFieldUpdate("topicName", event.currentTarget.value)
                }
              />
            </div>

            <div className="input-wrapper">
              <button
                type="button"
                onClick={handleFormSubmit}
                className="button button-primary button-spaced"
              >
                Add Topic
              </button>
            </div>
          </div>
        </div>
      </div>

      {loader && <PageLoader />}
    </div>
  );
};

export default withAuthentication(AddTopicPage);
