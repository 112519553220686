import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from '../../common/Environment';

import Header from '../../components/Header';
import PageLoader from '../../components/PageLoader';
import AdminService from '../../services/adminService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './AddClassPage.scss';

const AddClassPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    className: '',
    displayOrder: '',
  });
  const [loader, setLoader] = useState(false);
  const [showServerErrors, setShowServerErrors] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const adminService = new AdminService();

  const handleFormSubmit = () => {
    const valid = validateFormData();

    if (valid) {
      setLoader(true);

      adminService
        .createClass(formData)
        .then((responseData) => {
          setLoader(false);
          if (responseData && responseData.data) {
            navigate(NavigationPaths.CLASSESPAGE);
          }
        })
        .catch((errorData) => {
          setLoader(false);
          setShowServerErrors(
            errorData.message ||
              'Something went wrong. Please verify all the data entered is correct and then try again.'
          );
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        });
    }
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    const parsedValue =
      fieldName === 'displayOrder' ? parseInt(fieldValue) : fieldValue;

    setFormData((state) => ({
      ...state,
      [`${fieldName}`]: parsedValue,
    }));
  };

  const validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    if (formData.className === '') {
      errorMessagesArr.push('Class Name is a required field.');
      isFormValid = false;
    }

    if (formData.displayOrder === '') {
      errorMessagesArr.push('Display Order is a required field.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      setShowServerErrors('');
      setShowValidationErrors(true);
      setValidationErrors(errorMessagesArr);

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      setValidationErrors([]);
      setShowServerErrors('');
      setShowValidationErrors(false);
    }

    return isFormValid;
  };

  return (
    <div className='page-container' style={styles}>
      <Helmet>
        <title>Worksheet Warehouse - Add Class</title>
      </Helmet>

      <Header />

      <SideNavigation />

      <div className='addclass-container'>
        <div className='addclass-container-wrapper'>
          <h1 className='addclass-container-title'>Add Class</h1>

          <div className='addclass-container-content'>
            {showValidationErrors && (
              <div className='error-wrapper'>
                <h6>Error!</h6>

                {validationErrors.map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            )}

            {showServerErrors !== '' && (
              <div className='error-wrapper'>
                <h6>Error!</h6>

                <p>{showServerErrors}</p>
              </div>
            )}

            <div className='input-wrapper'>
              <label>Class Name</label>
              <input
                type='text'
                className='input-field'
                placeholder='Class Name'
                value={formData.className}
                onChange={(event) =>
                  handleFieldUpdate('className', event.currentTarget.value)
                }
              />
            </div>

            <div className='input-wrapper'>
              <label>Display Order</label>
              <input
                type='number'
                className='input-field'
                placeholder='Display Order'
                value={formData.displayOrder}
                onChange={(event) =>
                  handleFieldUpdate('displayOrder', event.currentTarget.value)
                }
              />
            </div>

            <div className='input-wrapper'>
              <button
                type='button'
                onClick={handleFormSubmit}
                className='button button-primary button-spaced'
              >
                Add Class
              </button>
            </div>
          </div>
        </div>
      </div>

      {loader && <PageLoader />}
    </div>
  );
};

export default withAuthentication(AddClassPage);
