import axios from 'axios';
import get from 'lodash/get';
import { API_ROOT_URL, REACT_APP_CDN_ROOT_URL } from '../common/Environment';

const axiosInstance = axios.create({
  baseURL: API_ROOT_URL,
  timeout: 60000,
  headers: {
    'Access-Control-Allow-Headers':
      'Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Authorization',
  },
  withCredentials: false,
});

const cdnAxiosInstance = axios.create({
  baseURL: REACT_APP_CDN_ROOT_URL,
  timeout: 60000,
  headers: {
    'Access-Control-Allow-Headers':
      'Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Authorization',
  },
  withCredentials: false,
});

axiosInstance.interceptors.response.use(
  (responseData) => {
    console.log('+++++++++', 'Interceptor Output Starts', '+++++++++');
    console.log(responseData.data);
    console.log('+++++++++', 'Interceptor Output Ends', '+++++++++');

    return responseData.data;
  },
  (errorData) => {
    const errorResponse = get(errorData, 'response.data');

    if (!errorResponse) {
      return Promise.reject({
        message:
          'ERROR: Network Connection Failure. Please Check Your Internet Access and Try Again.',
      });
    }

    console.log('+++++++++', 'Interceptor Output Starts', '+++++++++');
    console.log(errorResponse);
    console.log('+++++++++', 'Interceptor Output Ends', '+++++++++');

    return Promise.reject(errorResponse);
  }
);

cdnAxiosInstance.interceptors.response.use(
  (responseData) => {
    console.log('+++++++++', 'Interceptor Output Starts', '+++++++++');
    console.log(responseData.data);
    console.log('+++++++++', 'Interceptor Output Ends', '+++++++++');

    return responseData.data;
  },
  (errorData) => {
    const errorResponse = get(errorData, 'response.data');

    if (!errorResponse) {
      return Promise.reject({
        message:
          'ERROR: Network Connection Failure. Please Check Your Internet Access and Try Again.',
      });
    }

    console.log('+++++++++', 'Interceptor Output Starts', '+++++++++');
    console.log(errorResponse);
    console.log('+++++++++', 'Interceptor Output Ends', '+++++++++');

    return Promise.reject(errorResponse);
  }
);

export {axiosInstance, cdnAxiosInstance};
