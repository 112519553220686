import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { NavigationPaths } from "../../common/Environment";

import Header from "../../components/Header";
import PageLoader from "../../components/PageLoader";
import AdminService from "../../services/adminService";
import SideNavigation from "../../components/SideNavigation";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";

import styles from "./AddSubtopicPage.scss";

const AddSubtopicPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    topicId: "",
    subtopicName: "",
  });
  const [topics, setTopics] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showServerErrors, setShowServerErrors] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const adminService = new AdminService();

  useEffect(() => {
    getTopics();
  }, []); // eslint-disable-line

  const handleFormSubmit = () => {
    const valid = validateFormData();

    if (valid) {
      setLoader(true);

      adminService
        .createSubtopic(formData)
        .then((responseData) => {
          setLoader(false);
          if (responseData && responseData.data) {
            navigate(NavigationPaths.SUBTOPICSPAGE);
          }
        })
        .catch((errorData) => {
          setLoader(false);
          setShowServerErrors(
            errorData.message ||
              "Something went wrong. Please verify all the data entered is correct and then try again."
          );
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        });
    }
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    setFormData((state) => ({
      ...state,
      [`${fieldName}`]: fieldValue,
    }));
  };

  const getTopics = () => {
    setLoader(true);

    adminService
      .getTopics({})
      .then((responseData) => {
        if (responseData) {
          setLoader(false);
          setTopics(responseData.data);
        }
      })
      .catch((errorData) => {
        setLoader(false);
        console.log(errorData);
      });
  };

  const validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    if (formData.topicId === "") {
      errorMessagesArr.push("Topic is a required field.");
      isFormValid = false;
    }

    if (formData.subtopicName === "") {
      errorMessagesArr.push("Sub Topic Name is a required field.");
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      setShowServerErrors("");
      setShowValidationErrors(true);
      setValidationErrors(errorMessagesArr);

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      setValidationErrors([]);
      setShowServerErrors("");
      setShowValidationErrors(false);
    }

    return isFormValid;
  };

  return (
    <div className="page-container" style={styles}>
      <Helmet>
        <title>Worksheet Warehouse - Add Sub Topic</title>
      </Helmet>

      <Header />

      <SideNavigation />

      <div className="addsubtopic-container">
        <div className="addsubtopic-container-wrapper">
          <h1 className="addsubtopic-container-title">Add Sub Topic</h1>

          <div className="addsubtopic-container-content">
            {showValidationErrors && (
              <div className="error-wrapper">
                <h6>Error!</h6>

                {validationErrors.map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            )}

            {showServerErrors !== "" && (
              <div className="error-wrapper">
                <h6>Error!</h6>

                <p>{showServerErrors}</p>
              </div>
            )}

            <div className="input-wrapper">
              <label>Topics</label>
              <select
                value={formData.topicId}
                className="input-select"
                onChange={(event) =>
                  handleFieldUpdate("topicId", event.target.value)
                }
              >
                <option value={""} disabled>
                  Choose a Topic
                </option>
                {topics.map((topicDetails, index) => {
                  return (
                    <option key={index} value={topicDetails._id}>
                      {topicDetails.topicName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-wrapper">
              <label>Sub Topic Name</label>
              <input
                type="text"
                className="input-field"
                placeholder="Sub Topic Name"
                value={formData.subtopicName}
                onChange={(event) =>
                  handleFieldUpdate("subtopicName", event.currentTarget.value)
                }
              />
            </div>

            <div className="input-wrapper">
              <button
                type="button"
                onClick={handleFormSubmit}
                className="button button-primary button-spaced"
              >
                Add Sub Topic
              </button>
            </div>
          </div>
        </div>
      </div>

      {loader && <PageLoader />}
    </div>
  );
};

export default withAuthentication(AddSubtopicPage);
