import React from "react";

import { NavigationPaths } from "../common/Environment";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HomePage from "../containers/HomePage";
import LoginPage from "../containers/LoginPage";

import ClassesPage from "../containers/ClassesPage";
import AddClassPage from "../containers/AddClassPage";
import ClassDetailsPage from "../containers/ClassDetailsPage";
import SubjectsPage from "../containers/SubjectsPage";
import AddSubjectPage from "../containers/AddSubjectPage";
import SubjectDetailsPage from "../containers/SubjectDetailsPage";
import TopicsPage from "../containers/TopicsPage";
import AddTopicPage from "../containers/AddTopicPage";
import TopicDetailsPage from "../containers/TopicDetailsPage";
import SubtopicsPage from "../containers/SubtopicsPage";
import AddSubtopicPage from "../containers/AddSubtopicPage";
import SubtopicDetailsPage from "../containers/SubtopicDetailsPage";
import WorksheetsPage from "../containers/WorksheetsPage";
import AddWorksheetPage from "../containers/AddWorksheetPage";
import WorksheetDetailsPage from "../containers/WorksheetDetailsPage";
import CopyWorksheetPage from "../containers/CopyWorksheetPage";
import PrintAnalyticsPage from "../containers/PrintAnalyticsPage";

const Navigator = () => {
  return (
    <Router>
      <Routes>
        <Route exact path={NavigationPaths.LOGIN} element={<LoginPage />} />

        <Route exact path={NavigationPaths.HOMEPAGE} element={<HomePage />} />

        <Route
          exact
          path={NavigationPaths.CLASSESPAGE}
          element={<ClassesPage />}
        />

        <Route
          exact
          path={NavigationPaths.CREATECLASSESPAGE}
          element={<AddClassPage />}
        />

        <Route
          exact
          path={NavigationPaths.CLASSESIDPAGE}
          element={<ClassDetailsPage />}
        />

        <Route
          exact
          path={NavigationPaths.SUBJECTSPAGE}
          element={<SubjectsPage />}
        />

        <Route
          exact
          path={NavigationPaths.CREATESUBJECTSPAGE}
          element={<AddSubjectPage />}
        />

        <Route
          exact
          path={NavigationPaths.SUBJECTSIDPAGE}
          element={<SubjectDetailsPage />}
        />

        <Route
          exact
          path={NavigationPaths.TOPICSPAGE}
          element={<TopicsPage />}
        />

        <Route
          exact
          path={NavigationPaths.CREATETOPICSPAGE}
          element={<AddTopicPage />}
        />

        <Route
          exact
          path={NavigationPaths.TOPICSIDPAGE}
          element={<TopicDetailsPage />}
        />

        <Route
          exact
          path={NavigationPaths.SUBTOPICSPAGE}
          element={<SubtopicsPage />}
        />

        <Route
          exact
          path={NavigationPaths.CREATESUBTOPICSPAGE}
          element={<AddSubtopicPage />}
        />

        <Route
          exact
          path={NavigationPaths.SUBTOPICSIDPAGE}
          element={<SubtopicDetailsPage />}
        />

        <Route
          exact
          path={NavigationPaths.WORKSHEETSPAGE}
          element={<WorksheetsPage />}
        />

        <Route
          exact
          path={NavigationPaths.CREATEWORKSHEETSPAGE}
          element={<AddWorksheetPage />}
        />

        <Route
          exact
          path={NavigationPaths.WORKSHEETSIDPAGE}
          element={<WorksheetDetailsPage />}
        />

        <Route
          exact
          path={NavigationPaths.COPYWORKSHEETSIDPAGE}
          element={<CopyWorksheetPage />}
        />

        <Route
          exact
          path={NavigationPaths.PRINTANALYTICSPAGE}
          element={<PrintAnalyticsPage />}
        />
      </Routes>
    </Router>
  );
};

export default Navigator;
