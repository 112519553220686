import { useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const SweetAlert = withReactContent(Swal);

const Confirmation = (props) => {
  useEffect(() => {
    SweetAlert.fire({
      showCancelButton: true,
      allowOutsideClick: false,
      icon: props.icon || 'warning',
      cancelButtonColor: 'r#003c6c',
      confirmButtonColor: '#1fae0f',
      title: props.title || 'Are you sure?',
      confirmButtonText: props.confirmText || 'Proceed',
      text: props.description || "You won't be able to revert this!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.actionHandler();
      } else {
        props.resetHandler();
      }
    });
  }, []); // eslint-disable-line

  return null;
};

export default Confirmation;
