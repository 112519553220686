import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { NavigationPaths, ReduxConstants } from '../../common/Environment';

import Logo from '../../images/logo.svg';
import styles from './Header.scss';

const Header = (props) => {
  const dispatch = useDispatch();

  const handleUserLogout = () => {
    dispatch({
      payload: {},
      type: ReduxConstants.LOGOUT,
    });
  };

  return (
    <header className='page-header' style={styles}>
      <div className='page-header-left'>
        <Link to={NavigationPaths.HOMEPAGE}>
          <div className='page-branding d-flex align-items-center justify-content-start'>
            <img src={Logo} className='page-branding-logo' alt='WW' />
            <h2 className='page-branding-text'>Worksheet Warehouse</h2>
          </div>
        </Link>
      </div>

      {!props.hideLogout && (
        <div className='page-header-right'>
          <button
            type='button'
            className='button button-link'
            onClick={handleUserLogout}
          >
            Log Out
          </button>
        </div>
      )}
    </header>
  );
};

export default Header;
