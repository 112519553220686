import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { NavigationPaths } from "../../common/Environment";

import ReactSelect from "react-select";
import startCase from "lodash/startCase";
import Header from "../../components/Header";
import Uploader from "../../components/Uploader";
import PageLoader from "../../components/PageLoader";
import AdminService from "../../services/adminService";
import SideNavigation from "../../components/SideNavigation";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";

import styles from "./AddWorksheetPage.scss";

const AddWorksheetPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    skill: "",
    fileUrl: "",
    classId: [],
    topicId: "",
    keywords: "",
    subjectId: "",
    subtopicId: [],
    difficulty: "",
    screenshotUrl: "",
    worksheetTitle: "",
    learningObjective: "",
    includesAnswerKey: "false",
  });
  const [topics, setTopics] = useState([]);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showServerErrors, setShowServerErrors] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const adminService = new AdminService();

  useEffect(() => {
    getResources();
  }, []); // eslint-disable-line

  const getResources = () => {
    setLoader(true);

    adminService
      .getResources()
      .then((responseData) => {
        if (responseData) {
          setLoader(false);
          setClasses(
            responseData.data?.classes?.map((c) => ({
              label: c.className,
              value: c._id,
            }))
          );
          setSubjects(responseData.data?.subjects);
          setTopics(responseData.data?.topics);
        }
      })
      .catch((errorData) => {
        setLoader(false);
        console.log(errorData);
      });
  };

  const handleFormSubmit = () => {
    const valid = validateFormData();

    if (valid) {
      setLoader(true);

      adminService
        .createWorksheet({
          ...formData,
          classId: formData.classId.map((c) => c.value),
          subtopicId: formData.subtopicId.map((st) => st.value),
          includesAnswerKey: formData.includesAnswerKey === "true",
          keywords: formData.keywords
            ? formData.keywords
                .split(",")
                .map((keyword) => startCase(keyword.trim().toLowerCase()))
            : [],
        })
        .then((responseData) => {
          setLoader(false);
          if (responseData && responseData.data) {
            navigate(NavigationPaths.WORKSHEETSPAGE);
          }
        })
        .catch((errorData) => {
          setLoader(false);
          setShowServerErrors(
            errorData.message ||
              "Something went wrong. Please verify all the data entered is correct and then try again."
          );
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        });
    }
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    setFormData((state) => ({
      ...state,
      [`${fieldName}`]: fieldValue,
    }));

    if (fieldName === "topicId") {
      setFormData((state) => ({
        ...state,
        subtopicId: [],
      }));
      findSubtopics(fieldValue);
    }
  };

  const findSubtopics = (topicId) => {
    setLoader(true);

    adminService
      .findSubtopics({ topicId })
      .then((responseData) => {
        if (responseData) {
          setLoader(false);
          setSubtopics(
            responseData.data?.map((st) => ({
              label: st.subtopicName,
              value: st._id,
            }))
          );
        }
      })
      .catch((errorData) => {
        setLoader(false);
        console.log(errorData);
      });
  };

  const validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    if (!formData.classId.length) {
      errorMessagesArr.push("Classes are required.");
      isFormValid = false;
    }

    if (formData.subjectId === "") {
      errorMessagesArr.push("Subject is a required field.");
      isFormValid = false;
    }

    if (formData.topicId === "") {
      errorMessagesArr.push("Topic is a required field.");
      isFormValid = false;
    }

    if (!formData.subtopicId.length) {
      errorMessagesArr.push("Sub Topics are required.");
      isFormValid = false;
    }

    if (formData.worksheetTitle === "") {
      errorMessagesArr.push("Worksheet Title is a required field.");
      isFormValid = false;
    }

    if (formData.skill === "") {
      errorMessagesArr.push("Skill is a required field.");
      isFormValid = false;
    }

    if (formData.difficulty === "") {
      errorMessagesArr.push("Difficulty is a required field.");
      isFormValid = false;
    }

    if (formData.learningObjective === "") {
      errorMessagesArr.push("Learning Objective is a required field.");
      isFormValid = false;
    }

    if (formData.fileUrl === "") {
      errorMessagesArr.push("File URL is a required field.");
      isFormValid = false;
    }

    if (formData.screenshotUrl === "") {
      errorMessagesArr.push("Screenshot URL is a required field.");
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      setShowServerErrors("");
      setShowValidationErrors(true);
      setValidationErrors(errorMessagesArr);

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      setValidationErrors([]);
      setShowServerErrors("");
      setShowValidationErrors(false);
    }

    return isFormValid;
  };

  return (
    <div className="page-container" style={styles}>
      <Helmet>
        <title>Worksheet Warehouse - Add Worksheet</title>
      </Helmet>

      <Header />

      <SideNavigation />

      <div className="addworksheet-container">
        <Uploader />

        <div className="addworksheet-container-wrapper">
          <h1 className="addworksheet-container-title">Add Worksheet</h1>

          <div className="addworksheet-container-content">
            {showValidationErrors && (
              <div className="error-wrapper">
                <h6>Error!</h6>

                {validationErrors.map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            )}

            {showServerErrors !== "" && (
              <div className="error-wrapper">
                <h6>Error!</h6>

                <p>{showServerErrors}</p>
              </div>
            )}

            <div className="input-wrapper">
              <label>Classes</label>
              <ReactSelect
                isMulti={true}
                options={classes}
                isSearchable={true}
                className="input-select"
                value={formData.classId}
                closeMenuOnSelect={false}
                onChange={(value) => handleFieldUpdate("classId", value)}
              />
            </div>

            <div className="input-wrapper">
              <label>Subjects</label>
              <select
                value={formData.subjectId}
                className="input-select"
                onChange={(event) =>
                  handleFieldUpdate("subjectId", event.target.value)
                }
              >
                <option value={""} disabled>
                  Choose a subject
                </option>
                {subjects.map((subjectDetails, index) => {
                  return (
                    <option key={index} value={subjectDetails._id}>
                      {subjectDetails.subjectName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-wrapper">
              <label>Topics</label>
              <select
                value={formData.topicId}
                className="input-select"
                onChange={(event) =>
                  handleFieldUpdate("topicId", event.target.value)
                }
              >
                <option value={""} disabled>
                  Choose a Topic
                </option>
                {topics.map((topicDetails, index) => {
                  return (
                    <option key={index} value={topicDetails._id}>
                      {topicDetails.topicName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-wrapper">
              <label>Sub Topics</label>
              <ReactSelect
                isMulti={true}
                options={subtopics}
                isSearchable={true}
                className="input-select"
                value={formData.subtopicId}
                closeMenuOnSelect={false}
                isDisabled={
                  !formData.topicId
                }
                onChange={(value) => handleFieldUpdate("subtopicId", value)}
              />
            </div>

            <div className="input-wrapper">
              <label>Worksheet Title</label>
              <input
                type="text"
                className="input-field"
                placeholder="Worksheet Title"
                value={formData.worksheetTitle}
                onChange={(event) =>
                  handleFieldUpdate("worksheetTitle", event.currentTarget.value)
                }
              />
            </div>

            <div className="input-wrapper">
              <label>Skill</label>
              <input
                type="text"
                className="input-field"
                placeholder="Skill"
                value={formData.skill}
                onChange={(event) =>
                  handleFieldUpdate("skill", event.currentTarget.value)
                }
              />
            </div>

            <div className="input-wrapper">
              <label>Difficulty</label>
              <input
                type="text"
                className="input-field"
                placeholder="Difficulty"
                value={formData.difficulty}
                onChange={(event) =>
                  handleFieldUpdate("difficulty", event.currentTarget.value)
                }
              />
            </div>

            <div className="input-wrapper">
              <label>Learning Objective</label>
              <textarea
                rows={3}
                type="text"
                className="input-field"
                placeholder="Learning Objective"
                value={formData.learningObjective}
                onChange={(event) =>
                  handleFieldUpdate(
                    "learningObjective",
                    event.currentTarget.value
                  )
                }
              />
            </div>

            <div className="input-wrapper">
              <label>File URL</label>
              <input
                type="text"
                className="input-field"
                placeholder="File URL"
                value={formData.fileUrl}
                onChange={(event) =>
                  handleFieldUpdate("fileUrl", event.currentTarget.value)
                }
              />
            </div>

            <div className="input-wrapper">
              <label>Screenshot URL</label>
              <input
                type="text"
                className="input-field"
                placeholder="Screenshot URL"
                value={formData.screenshotUrl}
                onChange={(event) =>
                  handleFieldUpdate("screenshotUrl", event.currentTarget.value)
                }
              />
            </div>

            <div className="input-wrapper">
              <label>Keywords</label>
              <input
                type="text"
                className="input-field"
                value={formData.keywords}
                placeholder="Enter comma separated keywords"
                onChange={(event) =>
                  handleFieldUpdate("keywords", event.currentTarget.value)
                }
              />
            </div>

            <div className="input-wrapper">
              <label>Includes Answer Key</label>
              <select
                value={formData.includesAnswerKey}
                className="input-select"
                onChange={(event) =>
                  handleFieldUpdate("includesAnswerKey", event.target.value)
                }
              >
                <option value={"false"}>No</option>
                <option value={"true"}>Yes</option>
              </select>
            </div>

            <div className="input-wrapper">
              <button
                type="button"
                onClick={handleFormSubmit}
                className="button button-primary button-spaced"
              >
                Add Worksheet
              </button>
            </div>
          </div>
        </div>
      </div>

      {loader && <PageLoader />}
    </div>
  );
};

export default withAuthentication(AddWorksheetPage);
