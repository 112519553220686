import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { tableDataParser } from './dataParser';
import { NavigationPaths } from '../../common/Environment';

import Table from '../../components/Table';
import Header from '../../components/Header';
import AdminService from '../../services/adminService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './WorksheetsPage.scss';

const WorksheetsPage = () => {
  const [loader, setLoader] = useState(false);
  const [tableContent, setTableContent] = useState([]);
  const tableHeaders = ['S. No.', 'Database ID', "Worksheet", 'Subtopics', 'Topic', 'Subject', 'Classes', 'Active'];

  const adminService = new AdminService();

  useEffect(() => {
    getWorksheets();
  }, []); // eslint-disable-line

  const getWorksheets = () => {
    setLoader(true);

    adminService
      .getWorksheets()
      .then((responseData) => {
        if (responseData) {
          const parsedContentArr = tableDataParser(responseData.data);

          setLoader(false);

          setTableContent(parsedContentArr);
        }
      })
      .catch((errorData) => {
        setLoader(false);
        console.log(errorData);
      });
  };

  const handleWorksheetToggle = (worksheetDetails) => {
    if (Array.isArray(worksheetDetails)) {
      setLoader(true);
  
      adminService
        .updateWorksheet({ worksheetId: worksheetDetails[1], isActive: worksheetDetails[7] === 'Yes' ? false : true })
        .then(() => getWorksheets())
        .catch((errorData) => {
          setLoader(false);
          console.log(errorData);
        });
    }
  };

  return (
    <div className='page-container' style={styles}>
      <Helmet>
        <title>Worksheet Warehouse - Worksheets</title>
      </Helmet>

      <Header />

      <SideNavigation />

      <div className='data-container'>
        <div className='data-container-wrapper'>
          <div className='data-container-header d-flex align-items-center justify-content-between'>
            <h1 className='data-container-title'>
              Worksheets
            </h1>
            <Link
                to={NavigationPaths.CREATEWORKSHEETSPAGE}
                className="button button-primary"
              >
                Add Worksheet
              </Link>
          </div>

          <Table
            hasActions={true}
            hasDisable={true}
            loaderState={loader}
            headerData={tableHeaders}
            tableData={tableContent}
            disablePath={handleWorksheetToggle}
            editPath={NavigationPaths.WORKSHEETSIDPAGE}
            />
        </div>
      </div>
    </div>
  );
};

export default withAuthentication(WorksheetsPage);
