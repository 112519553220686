import React, { useState } from 'react';

import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PageLoader from '../PageLoader';
import AdminService from '../../services/adminService';

import styles from './Uploader.scss';

const Uploader = (props) => {
  const adminService = new AdminService();
  const [loader, setLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileInput, setShowFileInput] = useState(true);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [uploadedMediaIcon, setUploadedMediaIcon] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleMediaUpload = () => {
    if (!selectedFile) {
      return;
    }

    const dataPayload = {
      file: selectedFile,
      directoryName: selectedFile.type === "application/pdf" ? "printable-files" : "printable-thumbnails",
    };

    setLoader(true);
    adminService
      .uploadMedia(dataPayload)
      .then((responseData) => {
        setLoader(false);
        if (responseData.data) {
          setIsFileUploaded(true);
          setUploadedMediaIcon(responseData.data.url);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const resetMediaUpload = () => {
    setIsFileUploaded(false);
    setUploadedMediaIcon(null);
    setSelectedFile(null);
    setTimeout(() => {
      setShowFileInput(true);
    }, 1000);
  };

  return (
    <div className='image-uploader' style={styles}>
      {!isFileUploaded ? (
        <div className='image-uploader-chooser d-flex align-items-center justify-content-start'>
          <div className='upload-area d-flex align-items-center justify-content-start'>
            <label
              className='upload-area-label position-relative'
              forName='imageUploader'
            >
              {showFileInput && (
                <input
                  accept='image/*,application/pdf'
                  type='file'
                  id='imageUploader'
                  name='imageUploader'
                  className='upload-area-input'
                  onChange={(event) => handleFileSelect(event)}
                />
              )}
              <h6>
                {selectedFile && selectedFile.name
                  ? selectedFile.name
                  : 'Choose your file to upload.'}
              </h6>
            </label>

            <button
              type='button'
              className='upload-area-trigger'
              onClick={handleMediaUpload}
            >
              Upload File
            </button>
          </div>
        </div>
      ) : (
        <div className='image-uploader-display d-flex align-items-center justify-content-start'>
          <div className='display-area d-flex align-items-center justify-content-start'>
            <div className='display-area-label'>
              <h6>{uploadedMediaIcon}</h6>
            </div>

            <button
              type='button'
              className='display-area-trigger'
              onClick={resetMediaUpload}
            >
              <FontAwesomeIcon icon={faRedo} />
            </button>
          </div>
        </div>
      )}

      {loader && <PageLoader />}
    </div>
  );
};

export default Uploader;
