import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table as ReactTable } from 'react-bootstrap';

import Confirmation from '../Confirmation';
import Loading from '../../images/loader.gif';

import styles from './Table.scss';

const Table = (props) => {
  const [rowIdentifier, setRowIdentifier] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const getRenderingTableData = () => {
    const { headerData, tableData, hasActions, editPath, hasDisable } = props;

    if (
      tableData === undefined ||
      tableData === null ||
      typeof tableData !== 'object' ||
      tableData.length === 0
    ) {
      return (
        <tr className='text-center'>
          <td colSpan={headerData.length + hasActions ? 1 : 0}>
            No Records Available!
          </td>
        </tr>
      );
    }

    let tableDataArr = [];
    for (let rowIndex = 0; rowIndex < tableData.length; rowIndex++) {
      let columnDataArr = [];

      for (
        let columnIndex = 0;
        columnIndex < tableData[rowIndex].length;
        columnIndex++
      ) {
        let currentData = tableData[rowIndex][columnIndex];

        if (currentData && typeof currentData === 'string') {
          if (currentData.length !== 0 && currentData.length > 35) {
            currentData = `${currentData.substr(0, 35)}...`;
          }
        }

        columnDataArr.push(<td key={columnIndex}>{currentData}</td>);
      }

      if (hasActions) {
        if (hasDisable) {
          columnDataArr.push(
            <td key={`TABLE_ACTIONS_${rowIndex}`}>
              <div className='d-flex align-items-center justify-content-start'>
                <Link
                  to={editPath.replace(':id', tableData[rowIndex][1])}
                  className='button button-link'
                >
                  Edit
                </Link>
                <span className='vertical-separator' />
                <button
                  type='button'
                  className='button button-link'
                  onClick={() => toggleConfirmation(tableData[rowIndex])}
                >
                  Toggle
                </button>
              </div>
            </td>
          );
        } else {
          columnDataArr.push(
            <td key={`TABLE_ACTIONS_${rowIndex}`}>
              <Link
                to={editPath.replace(':id', tableData[rowIndex][1])}
                className='button button-link'
              >
                View
              </Link>
            </td>
          );
        }
      }

      if (columnDataArr.length !== 0) {
        tableDataArr.push(<tr key={rowIndex}>{columnDataArr}</tr>);
      }
    }

    return tableDataArr;
  };

  const toggleConfirmation = (rowIdentifier = null) => {
    setRowIdentifier(rowIdentifier);
    setShowConfirmation(!showConfirmation);
  };

  const confirmToggleHandler = () => {
    if (props.disablePath) {
      props.disablePath(rowIdentifier);
      toggleConfirmation(null);
    }
  };

  const { headerData, loaderState, hasActions } = props;

  return (
    <div className='dynamic-table position-relative' style={styles}>
      <ReactTable>
        {headerData !== undefined &&
          headerData !== null &&
          typeof headerData === 'object' &&
          headerData.length !== 0 && (
            <thead>
              <tr>
                {headerData.map((item, index) => {
                  return <td key={index}>{item}</td>;
                })}
                {hasActions && <td>Actions</td>}
              </tr>
            </thead>
          )}
        <tbody>{getRenderingTableData()}</tbody>
      </ReactTable>

      {loaderState && (
        <div className='table-loader d-flex align-items-center justify-content-center'>
          <div className='table-loader-wrapper'>
            <img src={Loading} alt='Loading' className='table-loader-image' />
            <h5 className='table-loader-text'>Loading...</h5>
          </div>
        </div>
      )}

      {showConfirmation && (
        <Confirmation
          resetHandler={toggleConfirmation}
          actionHandler={confirmToggleHandler}
          description={props.toggleDescription}
        />
      )}
    </div>
  );
};

export default Table;
