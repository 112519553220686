import React from 'react';
import Navigator from './navigator';

import configureStore from './store/CreateStore';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './styles/Styles.scss';

const persistStore = configureStore();
const { reduxStore, persistedStore } = persistStore;

function App() {
  return (
    <Provider store={reduxStore}>
      <PersistGate loading={null} persistor={persistedStore}>
        <Navigator />
      </PersistGate>
    </Provider>
  );
}

export default App;
