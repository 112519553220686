import React from 'react';
import { Link } from 'react-router-dom';
import { NavigationPaths } from '../../common/Environment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faFileAlt,
  faGraduationCap,
  faHome, faPrint, faSchool, faStickyNote,
} from '@fortawesome/free-solid-svg-icons';
import styles from './SideNavigation.scss';

const SideNavigation = () => {
  return (
    <aside className='page-sidebar' style={styles}>
      <ul className='page-sidebar-options'>
        <li className='sidebar-item'>
          <a href={NavigationPaths.HOMEPAGE} target='_self'>
            <FontAwesomeIcon icon={faHome} />
            Dashboard
          </a>
        </li>

        <li className='sidebar-item'>
          <Link to={NavigationPaths.CLASSESPAGE}>
            <FontAwesomeIcon icon={faSchool} />
            Classes
          </Link>
        </li>

        <li className='sidebar-item'>
          <Link to={NavigationPaths.SUBJECTSPAGE}>
            <FontAwesomeIcon icon={faGraduationCap} />
            Subjects
          </Link>
        </li>

        <li className='sidebar-item'>
          <Link to={NavigationPaths.TOPICSPAGE}>
            <FontAwesomeIcon icon={faBook} />
            Topics
          </Link>
        </li>

        <li className='sidebar-item'>
          <Link to={NavigationPaths.SUBTOPICSPAGE}>
            <FontAwesomeIcon icon={faStickyNote} />
            Sub Topics
          </Link>
        </li>

        <li className='sidebar-item'>
          <Link to={NavigationPaths.WORKSHEETSPAGE}>
            <FontAwesomeIcon icon={faFileAlt} />
            Worksheets
          </Link>
        </li>

        <li className='sidebar-item'>
          <Link to={NavigationPaths.PRINTANALYTICSPAGE}>
            <FontAwesomeIcon icon={faPrint} />
            Print Analytics
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default SideNavigation;
