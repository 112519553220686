import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { tableDataParser } from './dataParser';
import { NavigationPaths } from '../../common/Environment';

import Table from '../../components/Table';
import Header from '../../components/Header';
import AdminService from '../../services/adminService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './ClassesPage.scss';

const ClassesPage = () => {
  const [loader, setLoader] = useState(false);
  const [tableContent, setTableContent] = useState([]);
  const tableHeaders = ['S. No.', 'Database ID', 'Class', 'Active'];

  const adminService = new AdminService();

  useEffect(() => {
    getClasses();
  }, []); // eslint-disable-line

  const getClasses = () => {
    setLoader(true);

    adminService
      .getClasses()
      .then((responseData) => {
        if (responseData) {
          const parsedContentArr = tableDataParser(responseData.data);

          setLoader(false);

          setTableContent(parsedContentArr);
        }
      })
      .catch((errorData) => {
        setLoader(false);
        console.log(errorData);
      });
  };

  const handleClassToggle = (classDetails) => {
    if (Array.isArray(classDetails)) {
      setLoader(true);
  
      adminService
        .updateClass({ classId: classDetails[1], isActive: classDetails[3] === 'Yes' ? false : true })
        .then(() => getClasses())
        .catch((errorData) => {
          setLoader(false);
          console.log(errorData);
        });
    }
  };

  return (
    <div className='page-container' style={styles}>
      <Helmet>
        <title>Worksheet Warehouse - Classes</title>
      </Helmet>

      <Header />

      <SideNavigation />

      <div className='data-container'>
        <div className='data-container-wrapper'>
          <div className='data-container-header d-flex align-items-center justify-content-between'>
            <h1 className='data-container-title'>
              Classes
            </h1>
            <Link
                to={NavigationPaths.CREATECLASSESPAGE}
                className="button button-primary"
              >
                Add Class
              </Link>
          </div>

          <Table
            hasActions={true}
            hasDisable={true}
            loaderState={loader}
            headerData={tableHeaders}
            tableData={tableContent}
            disablePath={handleClassToggle}
            editPath={NavigationPaths.CLASSESIDPAGE}
            />
        </div>
      </div>
    </div>
  );
};

export default withAuthentication(ClassesPage);
