export const API_ROOT_URL = process.env.REACT_APP_API_ROOT_URL;
export const REACT_APP_CDN_ROOT_URL = process.env.REACT_APP_CDN_ROOT_URL;

// Start Navigation Constants
export const NavigationPaths = {
  LOGIN: '/',
  HOMEPAGE: '/home',
  CLASSESPAGE: '/classes',
  CLASSESIDPAGE: '/classes/:id',
  CREATECLASSESPAGE: '/classes/create',
  SUBJECTSPAGE: '/subjects',
  SUBJECTSIDPAGE: '/subjects/:id',
  CREATESUBJECTSPAGE: '/subjects/create',
  TOPICSPAGE: '/topics',
  TOPICSIDPAGE: '/topics/:id',
  CREATETOPICSPAGE: '/topics/create',
  SUBTOPICSPAGE: '/subtopics',
  SUBTOPICSIDPAGE: '/subtopics/:id',
  CREATESUBTOPICSPAGE: '/subtopics/create',
  WORKSHEETSPAGE: '/worksheets',
  WORKSHEETSIDPAGE: '/worksheets/:id',
  COPYWORKSHEETSIDPAGE: '/worksheets/:id/copy',
  CREATEWORKSHEETSPAGE: '/worksheets/create',
  PRINTANALYTICSPAGE: '/analytics/print',
};

// Start API Constants
const adminPrefix = 'admin';

export const ApiConstants = {
  LOGIN: `/${adminPrefix}/auth/login`,
  VERIFY_OTP: `/${adminPrefix}/auth/verify`,
  UPLOAD: `/${adminPrefix}/media`,
  CLASSES: `/${adminPrefix}/classes/`,
  CLASSES_ID: (classId) => `/${adminPrefix}/classes/${classId}`,
  SUBJECTS: `/${adminPrefix}/subjects/`,
  SUBJECTS_ID: (subjectId) => `/${adminPrefix}/subjects/${subjectId}`,
  SUBJECTS_CLASSES: `/${adminPrefix}/subjects/classes/combine`,
  TOPICS: `/${adminPrefix}/topics/`,
  TOPICS_ID: (topicId) => `/${adminPrefix}/topics/${topicId}`,
  FIND_TOPICS: `/${adminPrefix}/topics/find`,
  SUBTOPICS: `/${adminPrefix}/subtopics/`,
  SUBTOPICS_ID: (subtopicId) => `/${adminPrefix}/subtopics/${subtopicId}`,
  FIND_SUBTOPICS:`/${adminPrefix}/subtopics/find`,
  WORKSHEETS: `/${adminPrefix}/worksheets/`,
  WORKSHEETS_PRINT_ANALYTICS: `/${adminPrefix}/worksheets/analytics/print`,
  WORKSHEETS_RESOURCES: `/${adminPrefix}/worksheets/resources`,
  WORKSHEETS_ID: (worksheetId) => `/${adminPrefix}/worksheets/${worksheetId}`,
};

// Start Redux Constants
export const ReduxConstants = {
  LOGIN: `AUTH/login`,
  LOGOUT: `AUTH/logout`,
};
