import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { tableDataParser } from './dataParser';

import Table from '../../components/Table';
import Header from '../../components/Header';
import AdminService from '../../services/adminService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './PrintAnalyticsPage.scss';

const PrintAnalyticsPage = () => {
  const [loader, setLoader] = useState(false);
  const [tableContent, setTableContent] = useState([]);
  const tableHeaders = ['S. No.', 'UDISE Code', "Worksheet", 'Count'];

  const adminService = new AdminService();

  useEffect(() => {
    getPrintAnalytics();
  }, []); // eslint-disable-line

  const getPrintAnalytics = () => {
    setLoader(true);

    adminService
      .getPrintAnalytics()
      .then((responseData) => {
        if (responseData) {
          const parsedContentArr = tableDataParser(responseData.data);

          setLoader(false);

          setTableContent(parsedContentArr);
        }
      })
      .catch((errorData) => {
        setLoader(false);
        console.log(errorData);
      });
  };

  return (
    <div className='page-container' style={styles}>
      <Helmet>
        <title>Worksheet Warehouse - Print Analytics</title>
      </Helmet>

      <Header />

      <SideNavigation />

      <div className='data-container'>
        <div className='data-container-wrapper'>
          <div className='data-container-header d-flex align-items-center justify-content-between'>
            <h1 className='data-container-title'>
              Print Analytics
            </h1>
          </div>

          <Table
            hasActions={false}
            hasDisable={false}
            loaderState={loader}
            tableData={tableContent}
            headerData={tableHeaders}
            />
        </div>
      </div>
    </div>
  );
};

export default withAuthentication(PrintAnalyticsPage);
